import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { useForm } from "react-hook-form";
import { Btn } from "../../../../../AbstractElements";

export default function CartInfoForm({setStep, setFormData, formData}) {
  const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = (data) => {
      setFormData(prev => ({ ...prev, ...data }))
      setStep(prev => prev + 1)
    }
  return (
    <Form className="stepper-two needs-validation custom-input" onSubmit={handleSubmit(onSubmit)}>
     <Row className="g-3"> 
      <Col md={12}>
        <Label>{'Placeholder Name'}</Label>
        <input className={`form-control ${errors.name ? 'is-invalid':''}`} type="text" placeholder="Placeholder name" name='name' defaultValue={formData.name || ""} {...register('name', { required: true })}/>
      </Col>
      <Col xxl={4} sm={6}>
        <Label>{'Card Number'}</Label>
        <input className={`form-control ${errors.card ? 'is-invalid':''}`} type="number" placeholder="xxxx xxxx xxxx xxxx" name='card' defaultValue={formData.card || ""} {...register('card', { required: true })}/>
      </Col>
      <Col xxl={4} sm={6}>
        <Label>{'Expiration'}</Label>
        <input className={`form-control ${errors.expire ? 'is-invalid':''}`} type="number" placeholder="xx/xx" name='expire' defaultValue={formData.expire || ""} {...register('expire', { required: true })}/>
      </Col>
      <Col xxl={4}>
        <Label>{'Cvv Number'}</Label>
        <input className={`form-control ${errors.cvv ? 'is-invalid':''}`} type="number" name='cvv' defaultValue={formData.cvv || ""} {...register('cvv', { required: true })} />
      </Col>
      <Col xs={12}>
        <Label>{'Upload Documentation'}</Label>
        <input className={`form-control ${errors.file ? 'is-invalid':''}`} type="file" name='file' {...register('file', { required: true })}/>
      </Col>
      <Col xs={12}>
        <FormGroup check>
          <Input id="invalidCheck-a" type="checkbox" defaultValue="" required/>
          <Label htmlFor="invalidCheck-a" check>{'Correct Information'}</Label>
        </FormGroup>
      </Col>
      </Row>
      <div className="wizard-footer d-flex gap-2 justify-content-end">
        <Btn attrBtn={{ color: 'primary', onClick: () => setStep((prev) => prev - 1) }}>{'Back'}</Btn>
        <Btn attrBtn={{ color: 'primary' }}>{'Next'}</Btn>
      </div>
    </Form>
  );
}

import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { useForm } from "react-hook-form";
import { Btn } from "../../../../../AbstractElements";

export default function BasicInfoForm({setStep, setFormData, formData}) {
  const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = (data) => {
      setFormData(prev => ({ ...prev, ...data }))
      setStep(prev => prev + 1)
    }
  return (
    <Form className="stepper-one needs-validation custom-input" onSubmit={handleSubmit(onSubmit)}>
      <Row className="g-3">
        <Col sm={6}>
            <Label>{'Email Address'}<span className="txt-danger">{'*'}</span></Label>
            <input className={`form-control ${errors.email ? 'is-invalid':''}`} type="email" placeholder="your@gmail.com" name='email' defaultValue={formData.email || ""} {...register('email', { required: true })}/>
        </Col>
        <Col sm={6}>
            <Label>{'First Name'}<span className="txt-danger">{'*'}</span></Label>
            <input className={`form-control ${errors.firstName ? 'is-invalid':''}`} type="text" placeholder="Enter your name" name='firstName' defaultValue={formData.firstName || ""} {...register('firstName', { required: true })}/>
        </Col>
        <Col xs={12}>
            <Col sm={12}><Label>{'Password'}<span className="txt-danger">{'*'}</span></Label></Col>
            <input className={`form-control ${errors.password ? 'is-invalid':''}`} type="password" placeholder="Enter password" name="password" defaultValue={formData.password || ""} {...register('password', { required: true })}/>
        </Col>
        <Col xs={12}>
            <Col sm={12}><Label>{'Confirm Password'}<span className="txt-danger">{'*'}</span></Label></Col>
            <input className={`form-control ${errors.confirmPassword ? 'is-invalid':''}`} type="password" placeholder="Enter confirm password" name="confirmPassword" defaultValue={formData.confirmPassword || ""} {...register('confirmPassword', { required: true })}/>
        </Col>
        <Col xs={12}>
            <FormGroup check>
            <Input type="checkbox" defaultValue='' id="inputcheckwizard" required/>
            <Label className="mb-0" htmlFor="inputcheckwizard" check>{'Agree Terms'}</Label>
            </FormGroup>
        </Col>
      </Row>
      <div className="wizard-footer d-flex gap-2 justify-content-end">
        <Btn attrBtn={{ color: 'primary' }}>{'Next'}</Btn>
      </div>
    </Form>
  );
}

import { Link } from "react-router-dom";
import { Button, Form, FormGroup, Input, InputGroup, InputGroupText, Label } from "reactstrap";
import { Lock, Mail } from "react-feather";
import { Image } from "../../../AbstractElements";
import { CreateAccount, EmailAddress, ForgotPassword, Login, Password, RememberPassword, SignIn } from "../../../Constant";
import MediaIcons from "../../../CommonElements/MediaIcons";
import { useState } from "react";

export default function LoginForm() {
  const [isPasswordVisible,setisPasswordVisible] = useState(false);

  const toggle = () =>{ setisPasswordVisible(!isPasswordVisible)};
  var images = require.context("../../../assets/images", true);
  const dynamicImage = (image) => {
    return images(`./${image}`);
  };
  return (
    <div>
      <div>
        <Link className='logo' to={`${process.env.PUBLIC_URL}/dashboard/default`}>
          <Image className='img-fluid for-light' src={dynamicImage(`logo/logo.png`)} alt='looginpage' body={true} />
        </Link>
      </div>
      <div className='login-card1'>
        <Form className='theme-form login-form'>
          <div className='login-header text-center'>
            <h4>{Login}</h4>
            <h6>Welcome back! Log in to your account.</h6>
          </div>

          <FormGroup>
            <Label>{EmailAddress}</Label>
            <InputGroup>
              <InputGroupText>
                <Mail />
              </InputGroupText>
              <Input type='email' required placeholder='Test@gmail.com' />
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <Label>{Password}</Label>
            <InputGroup>
              <InputGroupText>
                <Lock />
              </InputGroupText>
              <Input type={isPasswordVisible ? "text" : "password"} name='login[password]' required  placeholder='*********'/>
              <div className='show-hide' onClick={toggle}>
              <span className={!isPasswordVisible ? "show" : ""}></span>
            </div>
            </InputGroup>
          </FormGroup>

          <FormGroup className='login-btn'>
            <div className='checkbox'>
              <Input id='checkbox1' type='checkbox' />
              <Label className='text-muted' for='checkbox1'>
                {RememberPassword}
              </Label>
            </div>
            <Link href='/pages/authentication/forgetpwd' className='link'>
              {ForgotPassword}?
            </Link>
          </FormGroup>
          <FormGroup>
            <Link to={"/"}>
              <Button className='btn-block' color='primary'>
                {SignIn}
              </Button>
            </Link>
          </FormGroup>
          <div className='login-social-title'>
            <h5>Sign in with Email</h5>
          </div>
          <FormGroup>
            <MediaIcons />
          </FormGroup>
          <p>
            Don't have account?
            <Link href='/pages/authentication/create-pwd' className='ms-2'>
              {CreateAccount}
            </Link>
          </p>
        </Form>
      </div>
    </div>
  );
}

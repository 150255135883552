import { Btn } from '../../AbstractElements';
import React, { Fragment, useState} from 'react';
import { Col, CardBody, CardFooter, Form, FormGroup, Label, Input} from 'reactstrap';
import SweetAlert from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { errorHandler } from '../../api/errorHandler.js';
import api from '../../api'; 

const CategoryCreateLayout = () => {

  const navigate = useNavigate();

  const token = localStorage.getItem("token")

  const [formValues, setFormValues] = useState({
    name: '',
  });


  const handleInputChange = (e) => {
    const { name, value } = e.target; 
    setFormValues((prevValues) => ({
      ...prevValues, 
      [name]: value, 
    }));
  };


  const handleSubmit = async (e) => {    
    try {

      const response = await api.post('/category/add',  formValues );
      
      if (response.status === 200 && response.data.success === true) {
        SweetAlert.fire({
          title: 'Başarılı!',
          text: 'Kategori Başarıyla Eklendi',
          icon: 'success',
          confirmButtonText: 'Tamam',
      });
      }
    } catch (error) {
      errorHandler(error, navigate)
  }};

  return (
    <Fragment>
      <CardBody>
        <Form className="theme-form" onSubmit={handleSubmit}>
        <FormGroup className="row">
          </FormGroup>
          <FormGroup className="row">
            <Label className="col-sm-3 col-form-label" htmlFor="inputText">{"Kategori Adı"}</Label>
            <Col sm="9">
              <Input className="form-control" type="text" placeholder="Kategori Adı Giriniz..." name="name" value={formValues.name}
                onChange={handleInputChange} />
            </Col>
          </FormGroup>
        </Form>
      </CardBody>
      <CardFooter>
        <Btn type='submit'attrBtn={{ color: 'primary', className: 'me-2' , onClick: () => handleSubmit()}  }>Kaydet</Btn>
        <Btn attrBtn={{ color: 'primary' }}>İptal</Btn>
      </CardFooter>
    </Fragment>
  );
};
export default CategoryCreateLayout;
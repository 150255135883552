import React, { Fragment } from "react";
import { Card, Col, CardBody, CardHeader } from "reactstrap";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { BasicDemo } from "../../../Constant";
import { H5 } from "../../../AbstractElements";

const containerStyle = {
  height: "500px",
};
const center = {
  lat: -3.745,
  lng: -38.523,
};

const BasicMapComp = () => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyAjeJEPREBQFvAIqDSZliF0WjQrCld-Mh0",
  });
  return (
    <Fragment>
      <Col xl="6" md="12">
        <Card>
          <CardHeader className="pb-0">
            <H5>{BasicDemo}</H5>
          </CardHeader>
          <CardBody>
            <div className="map-js-height">
              <div id="gmap-simple" className="map-block">
                {isLoaded ? (
                  <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={center}
                    zoom={10}
                  />
                ) : (
                  "Loading"
                )}
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Fragment>
  );
};

export default BasicMapComp;

import React, { Fragment } from 'react';
import { Breadcrumbs } from '../../../AbstractElements';
import CategoryListLayout from '../../../Component/Categories/CategoryListLayout';


const CreateCategoryPage = () => {
  console.log("Ana sayfadayım")
  return (
    <Fragment>
      <Breadcrumbs parent="Menü" title="Kategoriler" />
      <CategoryListLayout />
    </Fragment>
  );
};
export default CreateCategoryPage;
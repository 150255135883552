import React, { Fragment } from 'react';
import { Breadcrumbs } from '../../../AbstractElements';
import CategoryCreateLayout from '../../../Component/Categories/CategoryCreateLayout';


const CreateCategoryPage = () => {
  console.log("Ana sayfadayım")
  return (
    <Fragment>
      <Breadcrumbs parent="Menü" title="Kategori Oluştur" />
      <CategoryCreateLayout />
    </Fragment>
  );
};
export default CreateCategoryPage;
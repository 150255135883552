import React, { Fragment, useState, useEffect } from 'react';
import Files from 'react-files';
import { Container, Row, Col, Card, CardBody, CardHeader } from 'reactstrap';
import { Btn, H5, Image } from '../../../AbstractElements';

const UploadImageContain = ({ header = 'Varsayılan Başlık', onImageUpload, initialImage }) => {
    const [files, setFiles] = useState([]);
    const [imageSrc, setImageSrc] = useState(initialImage);

    // Dosya seçildiğinde tetiklenen fonksiyon
    const onFilesChange = (files) => {
        setFiles(files);
        const file = files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64 = reader.result;
                setImageSrc(base64);
                if (onImageUpload) onImageUpload(base64);
            };
            reader.readAsDataURL(file);
        }
    };

    // Dosya yükleme hatası olduğunda tetiklenen fonksiyon
    const onFilesError = (error) => {
        console.error("File Error:", error);
        setFiles([]);
    };

    // Dosyayı kaldırma fonksiyonu
    const deleteFile = () => {
        setFiles([]);
        setImageSrc(null);
        if (onImageUpload) onImageUpload(null);
    };

    return (
        <Fragment>
            <Container fluid={true}>
                <Row>
                    <Col sm='12'>
                        <Card>
                            <CardHeader className="pb-0">
                                <H5>{header}</H5>
                            </CardHeader>
                            <CardBody>
                                <Files
                                    className='files-dropzone fileContainer'
                                    onChange={onFilesChange}
                                    onError={onFilesError}
                                    accepts={['image/*']}
                                    multiple={false}
                                    maxFileSize={10000000}
                                    minFileSize={0}
                                    clickable
                                >
                                    {imageSrc ? (
                                        <div className='files-gallery'>
                                            <Image
                                                attrImage={{
                                                    className: 'files-gallery-item',
                                                    alt: 'img',
                                                    src: imageSrc,
                                                    width:'100%',
                                                    height: '600px'
                                                }}
                                            />
                                        </div>
                                    ) : (
                                        <div className='d-flex justify-content-center'>
                                            <Btn
                                                attrBtn={{ className: 'mt-2', type: 'button', color: 'primary' }}
                                            >
                                                Görsel Yükle
                                            </Btn>
                                        </div>
                                    )}
                                </Files>

                                {imageSrc && (
                                    <div className='d-flex justify-content-center'>
                                        <Btn
                                            attrBtn={{
                                                className: 'mt-2',
                                                color: 'danger',
                                                type: 'button',
                                                onClick: deleteFile
                                            }}
                                        >
                                            Görsel Kaldır
                                        </Btn>
                                    </div>
                                )}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};

export default UploadImageContain;

import { Check } from "react-feather";

export default function StepperHorizontal({step,isUpdated,isCompleted}) {
  return (
    <>
      <div className={`stepper-one stepper step ${step >= 1 ? "active" : ""} ${step > 1 ? 'editing' : ''}`}>
        <div className="step-circle">{step > 1 ? (<Check/>):(<span>{"1"}</span>)}</div>
        <div className="step-title">{'Basic Info'}</div>
        <div className="step-bar-left"></div>
        <div className="step-bar-right"></div>
      </div>
      <div className={`stepper-two step ${step >= 2 ? "active" : ""} ${step > 2 ? 'editing' : ''}`}>
        <div className="step-circle">{step > 2 ? (<Check/>):(<span>{"2"}</span>)}</div>
        <div className="step-title">{'Cart Info'}</div>
        <div className="step-bar-left"></div>
        <div className="step-bar-right"></div>
      </div>
      <div className={`stepper-four step ${step >= 3 ? "active" : ""} ${isUpdated ? 'editing' : ''} ${isCompleted ?'':'active'}`}>
        <div className="step-circle">{isUpdated ? <Check /> : isCompleted ? null : <span>{"3"}</span>}</div>
        <div className="step-title">{'Finish'}</div>
        <div className="step-bar-left"></div>
        <div className="step-bar-right"></div>
      </div>
    </>
  );
}

import { Col, Form, Row } from "reactstrap";
import { Btn, H6, P } from "../../../../../AbstractElements";
import { useState } from "react";

export default function FinishForm({setStep,setIsUpdated,setIsCompleted,isCompleted}) {
  const[isDisabled, setIsDisabled] = useState(false);
  const handleFinish = (e) => {
    alert("Successfully Completed")
    setIsDisabled(true);
    setIsUpdated(true);
    setIsCompleted(true);
  }
  const handleBack = () => {
    if (isCompleted) {
      setIsCompleted(false);
      setIsDisabled(false);
      setIsUpdated(false);
    }
    setStep((prev) => prev - 1);
  };
  
  return (
    <Form className='stepper-four needs-validation mt-3' onSubmit={handleFinish}>
     <Row className="g-3">
      <Col xs={12} className="m-0">
        <div className="successful-form">
          <H6>{'Congratulations'} </H6>
          <P>{'Completed Successfully'} </P>
        </div>
      </Col>
     </Row>
     <div className="wizard-footer d-flex gap-2 justify-content-end">
        <Btn attrBtn={{ color: 'primary', onClick: handleBack }}>{'Back'}</Btn>
        <Btn attrBtn={{ color: 'primary'}} disabled={isDisabled}>{'Finish'}</Btn>
      </div>
    </Form>
  );
}

import { Btn } from '../../AbstractElements';
import React, { Fragment, useState, useEffect} from 'react';
import { Col, CardBody, CardFooter, Form, FormGroup, Label, Input, Button, Media } from 'reactstrap';
import axios from "axios";
import SweetAlert from 'sweetalert2';
import { useLocation } from 'react-router';
import { errorHandler } from '../../api/errorHandler.js';
import api from '../../api'; 

const CategoryEditLayout = () => {

    const location = useLocation()
    const category = location.state?.categories; 
    const [editFormValues, setEditFormValues] = useState({});
    const [isLoaded, setIsLoaded] = useState(false);
    const token = localStorage.getItem("token")

  useEffect(() => {
    if (category) {
      setEditFormValues({
        name: category.name || '',
      });
      setIsLoaded(true);
    }
  }, [category]);


  const handleInputChange = (e) => {
    const { name, value } = e.target; 
    setEditFormValues((prevValues) => ({
      ...prevValues, 
      [name]: value, 
    }));
  };


  const handleSubmit = async (e) => {

    try {
      const response = await api.put(`category/update/${category._id}`,  editFormValues);
      
      if (response.status === 200 && response.data.success === true) {
        console.log(response)
        SweetAlert.fire({
          title: 'Başarılı!',
          text: 'Kategori Başarıyla Güncellendi',
          icon: 'success',
          confirmButtonText: 'Tamam',
      });
      }
    } catch (error) {
     errorHandler(error, navigator);
  }};

  return (
    <Fragment>
      <CardBody>
        
        <Form className="theme-form" onSubmit={handleSubmit}>        
          <FormGroup className="row">
            <Label className="col-sm-3 col-form-label" htmlFor="inputText">{"Kampanya Başlığı"}</Label>
            <Col sm="9">
              <Input className="form-control" type="text" placeholder="Kampanya Başlığı" name="name" value={editFormValues.name}
                onChange={handleInputChange} />
            </Col>
          </FormGroup>
        </Form>
        
      </CardBody>
      <CardFooter>
        <Btn type='submit'attrBtn={{ color: 'primary', className: 'me-2',  onClick: () => handleSubmit()}} >Kaydet</Btn>
        <Btn attrBtn={{ color: 'primary' }}>İptal</Btn>
      </CardFooter>
    </Fragment>
  );
};
export default CategoryEditLayout;
import { useState } from "react";
import { Card, CardBody, Col,Container,Row,CardHeader } from "reactstrap";
import BasicInfoForm from "./BasicInfoForm";
import StepperHorizontal from "./StepperHorizontal";
import CartInfoForm from "./CartInfoForm";
import FinishForm from "./FinishForm";
import { H5 } from "../../../../../AbstractElements";
import { FormWizardWithIcon } from "../../../../../Constant";

export default function FormWizardContain() {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({});
  const [isUpdated, setIsUpdated] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);

  return (
    <Container fluid={true}>
      <Row>
        <Col sm="12">
          <Card>
            <CardHeader className="pb-0">
              <H5>{FormWizardWithIcon}</H5>
            </CardHeader>
            <CardBody className="basic-wizard important-validation">
              <div className="stepper-horizontal" id="stepper1">
                <StepperHorizontal
                  step={step}
                  isUpdated={isUpdated}
                  isCompleted={isCompleted}
                />
              </div>
              <div id="msform">
                {step === 1 && (
                  <BasicInfoForm
                    setStep={setStep}
                    setFormData={setFormData}
                    formData={formData}
                  />
                )}
                {step === 2 && (
                  <CartInfoForm
                    setStep={setStep}
                    setFormData={setFormData}
                    formData={formData}
                  />
                )}
                {step === 3 && (
                  <FinishForm
                    setStep={setStep}
                    step={step}
                    setIsUpdated={setIsUpdated}
                    isCompleted={isCompleted}
                    setIsCompleted={setIsCompleted}
                  />
                )}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

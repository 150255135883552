import { Btn } from '../../../../AbstractElements';
import { Email, Password, Website, Submit, Cancel, Option, ContactNumber, CompanyName, Checkboxes, Radios, Disabled, title, shortDescription, conditions, usageInstructions, detailedDescription, startDate, endDate, CampaignDescription, CampaignConditions, CampaignHowToUse, CampaignDetail, CampaignStartDate, CampaignEndDate } from '../../../../Constant';
import React, { Fragment, useState} from 'react';
import { Row, Col, CardBody, CardFooter, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import ImageUpload from '../../../../Pages/Bonus UI/ImageUpload';
import axios from "axios";
import SweetAlert from 'sweetalert2';
import UploadImageContain from '../../../Bonus UI/ImageUpload';

const HorizontalLayout = () => {

  const token = localStorage.getItem("token")

  const api = axios.create({
    baseURL: 'https://sea-lion-app-erqaa.ondigitalocean.app/admin/',
  });

 

  const [formValues, setFormValues] = useState({
    title: '',
    shortDescription: '',
    conditions: '',
    usageInstructions: '',
    detailedDescription: '',
    startDate: '',
    endDate: '',
    priority: 'Düşük',  // Varsayılan düşük öncelik
    imageBase64: ''
  });

   // onChange handler - input değiştiğinde güncellenir
   const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleImageUpload = (base64Image) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      imageBase64: base64Image
    }));
  };

  const handleSubmit = async (e) => {

    console.log("Geldi?")

    // Tarih alanlarını istenen formata dönüştürüyoruz
    const formattedValues = {
      ...formValues,
      startDate: formValues.startDate ? new Date(formValues.startDate).toISOString() : '',
      endDate: formValues.endDate ? new Date(formValues.endDate).toISOString() : ''
    };

    console.log('Request Body:', JSON.stringify(formattedValues, null, 2));

    console.log(token)
    

    try {

      console.log(formattedValues)

      const response = await api.post('/company/campaign/add',  formattedValues  , {
        headers: {
          'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
        }
      }  );

      console.log(response)
      
      if (response.status === 200 && response.data.success === true) {
        console.log(response)
        SweetAlert.fire({
          title: 'Başarılı!',
          text: 'Kampanya Başarıyla Oluşturuldu',
          icon: 'success',
          confirmButtonText: 'Tamam',
      });
      }
    } catch (error) {
      console.log(error)
      SweetAlert.fire({
        title: 'Hata!',
        text: error,
        icon: 'error',
        confirmButtonText: 'Tamam',
    });
  }};

  return (
    <Fragment>
      <CardBody>
        <Form className="theme-form" onSubmit={handleSubmit}>
        <FormGroup className="row">
        <UploadImageContain onImageUpload={handleImageUpload} />
          </FormGroup>
          <FormGroup className="row">
            <Label className="col-sm-3 col-form-label" htmlFor="inputText">{"Kampanya Başlığı"}</Label>
            <Col sm="9">
              <Input className="form-control" type="text" placeholder="Kampanya Başlığı" name="title" value={formValues.title}
                onChange={handleInputChange} />
            </Col>
          </FormGroup>
          <FormGroup className="row">
            <Label className="col-sm-3 col-form-label" htmlFor="inputText">{CampaignDescription}</Label>
            <Col sm="9">
              <Input className="form-control" type="text" name="shortDescription" placeholder="Banner'da gözükecek kısa açıklama yazın" value={formValues.shortDescription}
                onChange={handleInputChange} />
            </Col>
          </FormGroup>
          <FormGroup className="row">
            <Label className="col-sm-3 col-form-label" htmlFor="inputText">{CampaignConditions}</Label>
            <Col sm="9">
              <Input className="form-control" type="text" name="conditions" placeholder="Kampanya için özel koşul varsa doldurun" value={formValues.conditions}
                onChange={handleInputChange}/>
            </Col>
          </FormGroup>
          <FormGroup className="row">
            <Label className="col-sm-3 col-form-label" htmlFor="inputText">{CampaignHowToUse}</Label>
            <Col sm="9">
              <Input className="form-control" type="text" name="usageInstructions" placeholder="Nasıl Kullanılır?" value={formValues.usageInstructions}
                onChange={handleInputChange} />
            </Col>
          </FormGroup>
          <FormGroup className="row">
            <Label className="col-sm-3 col-form-label" htmlFor="inputText">{CampaignDetail}</Label>
            <Col sm="9">
              <Input className="form-control" type="text" name="detailedDescription" placeholder="Detaylı Açıklama" value={formValues.detailedDescription}
                onChange={handleInputChange}/>
            </Col>
          </FormGroup>
          <FormGroup className="row">
            <Label className="col-sm-3 col-form-label" htmlFor="inputText">{CampaignStartDate}</Label>
            <Col sm="9">
              <Input className="form-control" type="date" name= "startDate" placeholder="Kampanya Başlangıç Tarihi" value={formValues.startDate}
                onChange={handleInputChange} />
            </Col>
          </FormGroup>
          <FormGroup className="row">
            <Label className="col-sm-3 col-form-label" htmlFor="inputText">{CampaignEndDate}</Label>
            <Col sm="9">
              <Input className="form-control" type="date" name= "endDate" placeholder="Kampanya Bitiş Tarihi" value={formValues.endDate}
                onChange={handleInputChange} />
            </Col>
          </FormGroup>
          <FormGroup className="mb-3">
            <Row>
              <Label className="col-form-label col-sm-3 pt-0">Öncelik</Label>
              <Col sm="9">
              <div className="form-check">
                  <Input type="radio" name="priority" value="Çok Yüksek" onChange={handleInputChange} checked={formValues.priority === 'Çok Yüksek'} /> Çok Yüksek Öncelikli
                </div>
                <div className="form-check">
                  <Input type="radio" name="priority" value="Yüksek" onChange={handleInputChange} checked={formValues.priority === 'Yüksek'} /> Yüksek Öncelikli
                </div>
                <div className="form-check">
                  <Input type="radio" name="priority" value="Orta" onChange={handleInputChange} checked={formValues.priority === 'Orta'} /> Orta Öncelikli
                </div>
                <div className="form-check">
                  <Input type="radio" name="priority" value="Düşük" onChange={handleInputChange} checked={formValues.priority === 'Düşük'} /> Düşük Öncelikli
                </div>
                <div className="form-check">
                  <Input type="radio" name="priority" value="Çok Düşük" onChange={handleInputChange} checked={formValues.priority === 'Çok Düşük'} /> Çok Düşük Öncelikli
                </div>
              </Col>
            </Row>
          </FormGroup>
          <div className="row mb-0 horizontal-layout">
            <Label className="col-sm-3 col-form-Label pb-0 mb-0">Kampanya Durumu</Label>
            <Col sm="9">
              <div className="mb-0">
                <div className="form-check form-check-inline checkbox checkbox-primary">
                  <Input id="inline-form-1" type="checkbox" name="isActive" checked={formValues.isActive} onChange={handleInputChange} />
                  <Label className="mb-0" htmlFor="inline-form-1">Aktif</Label>
                </div>
                <div className="form-check form-check-inline checkbox checkbox-primary">
                  <Input id="inline-form-2" type="checkbox" name="isPassive" checked={formValues.isPassive} onChange={handleInputChange} />
                  <Label className="mb-0" htmlFor="inline-form-2">Pasif</Label>
                </div>
              </div>
            </Col>
          </div>
        </Form>
      </CardBody>
      <CardFooter>
        <Btn type='submit'attrBtn={{ color: 'primary', className: 'me-2' , onClick: () => handleSubmit()}  }>Kaydet</Btn>
        <Btn attrBtn={{ color: 'primary' }}>İptal</Btn>
      </CardFooter>
    </Fragment>
  );
};
export default HorizontalLayout;
import React, { Fragment, useState, useEffect } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { productColumns, productData } from '../../Data/Ecommerce/product-list';
import { Btn, H6, Image } from '../../AbstractElements';
import DataTable from 'react-data-table-component';
import SweetAlert from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { errorHandler } from '../../api/errorHandler.js';
import api from '../../api'; // Axios yapılandırma dosyanızı import edin




 
const CampaignPageContain = () => {

  const navigate = useNavigate();

  const token = localStorage.getItem("token")

  const [dataList, setDataList] = useState([])

  const handleEdit = (item) => {
    navigate(`${process.env.PUBLIC_URL}/campaigns/edit`, {state: {campaign: item}});
  }


  const handleDelete = async(id) => {
    console.log(id)
    const result = await SweetAlert.fire({
      title: 'Emin misiniz?',
      text: 'Kampanya silme işlemi tamamlandığında bu işlem geri alınamaz!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'SİL',
      cancelButtonText: 'İPTAL',
      reverseButtons: true,
    });
    if (result.isConfirmed) {
      try {
        const response = await api.delete(`/company/campaign/delete/${id}`)
        if (response.status === 200) {
          SweetAlert.fire({
            title: 'Başarılı!',
            text: 'Kampanya başarıyla silindi.',
            icon: 'success',
            confirmButtonText: 'Tamam',
          });
          setDataList((prevData) => prevData.filter((item) => item.id !== id));
        }
      } catch (error) {
        errorHandler(error)
      }
    } else {
      SweetAlert.fire('Vazgeçildi!');
    }
  }

  const fetchData = async (e) => {

    let isMounted = true;

      try {
        const response = await api.get('/company/campaign/', {
          headers: {
            'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
          }
        }  );
        
        if (isMounted && response.status === 200 && response.data.success === true) {
          console.log(response.data)
          const products = response.data.data.campaigns.map((item) => ({
            id: item.id,
            image: (<div className="imageBase64"><Image attrImage={{ body: true, className: 'img-100 b-r-8', src: item.imageUrl, alt: '#' }} /></div>
              ),
            details: (
              <div>
                <H6>{item.title}</H6>
                <span>{item.shortDescription}</span>
              </div>
            ),
            start_date: new Date(item.startDate).toLocaleDateString(),
            end_date: new Date(item.endDate).toLocaleDateString(),
            priority: item.priority,
            action: (
              <div>
                <span>
                  <Btn attrBtn={{ color: 'danger', className: 'btn btn-xs', type: 'button', onClick: () => handleDelete(item.id)
                    
      }}>
                    Sil
                  </Btn>
                </span>
                <span>
                  <Btn attrBtn={{ color: 'primary', className: 'btn btn-xs ms-2', type: 'button', onClick: () => handleEdit(item) }}>
                    Düzenle
                  </Btn>
                </span>
              </div>
            ),
          }));
          
          setDataList(products);  
        }
      } catch (error) {
          errorHandler(error, navigate)
        }
      return () => {
        isMounted = false
      }
  
  }

  const columns = [
    {
      name: 'Görsel',
      selector: row => row.image,
      sortable: true,
    },
    {
      name: 'Detay',
      selector: row => row.details,
      grow: 2,
    },
    {
      name: 'Başlangıç Tarihi',
      selector: row => row.start_date,
      sortable: true,
    },
    {
      name: 'Bitiş Tarihi',
      selector: row => row.end_date,
      sortable: true,
    },
    {
      name: 'Öncelik',
      selector: row => row.priority,
      sortable: true,
    },
    {
      name: 'Aksiyon',
      selector: row => row.action,
      right: true,
    },
  ];

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Fragment>
      <div className="table-responsive product-table">
        <DataTable
          noHeader
          pagination
          paginationServer
          columns={columns}
          data={dataList}
        />
      </div>
    </Fragment>
  );
};


export default CampaignPageContain;
import React, { Fragment } from 'react';
import ApexChart from 'react-apexcharts';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col } from 'reactstrap';
import { H4, H6, Image } from '../../../AbstractElements';
import avtar from '../../../assets/images/avatar.jpg';
import { TotalEarning, WeeklyEvent } from '../../../Pages/DashBoard/ChartsData/TotalEvents';

const DailyEarning = () => {
  return (
    <Fragment>
      <Col xl="3" md="6" className="dash-xl-50 box-col-6">
        <Card className='pb-0 o-hidden earning-card'>
          <CardHeader className="earning-back"></CardHeader>
          <CardBody className="p-0">
            <div className="earning-content" style={{ position: 'relative' }}>
              < Image attrImage={{ className: 'img-fluid', src: `${avtar}`, alt: '' }} />
              <Link to={`${process.env.PUBLIC_URL}/blog/blogsingle`} >

                <H4>Yeni Müşteri</H4>
              </Link>
              <span>4 Kasım 2024</span>
              <H6>348</H6>
              <ApexChart id="earning-chart" options={TotalEarning.options} series={TotalEarning.series} type='area' height={145} />
            </div>
          </CardBody>
        </Card>
      </Col>
    </Fragment >
  );
};
export default DailyEarning;
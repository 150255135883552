import React, { Fragment } from "react";
import "./App.css";
import Routers from "./Routes";
import CustomProvider from "./_helper/customizer/CustomizerProvider";
import AnimationThemeProvider from "./_helper/AnimationTheme/AnimationThemeProvider";

function App() {
  return (
    <Fragment>
          {/* <GoogleChartProvider>
            <ChartjsProvider>
              <TableProvider>
                              <WishListProvider>
                                <BookmarkProvider>
                                 
                                    <CartProvider>
                                            <ProjectProvider>
                                              <AnimationThemeProvider> */}
                                              <AnimationThemeProvider>

                                                                    <CustomProvider>

                                                <Routers />
                                                </CustomProvider>
                                                </AnimationThemeProvider>

                                              {/* </AnimationThemeProvider>
          //                                   </ProjectProvider>
          //                                 </ProductProvider>
          //                           </CartProvider>
          //                         
          //                       </BookmarkProvider>
          //                     </WishListProvider>
          //             </CustomProvider>
          //     </TableProvider>
          //   </ChartjsProvider>
          // </GoogleChartProvider> */}
    </Fragment>
  );
}
export default App;

import React, { Fragment } from 'react';
import Breadcrumbs from '../../../CommonElements/Breadcrumbs';
import CampaignEditPage from './CampaignEdit';

const CampaignEdit = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="Kampanyalar" title="Kampanya Düzenle" />
      <CampaignEditPage />
    </Fragment>
  );
};
export default CampaignEdit;
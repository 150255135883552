import React, { Fragment } from 'react';
import Breadcrumbs from '../../../CommonElements/Breadcrumbs';
import CampaignPageContain from '../../../Component/Campaigns/CampaignPage';

const CampaignList = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="Kampanyalar" title="Kampanya Listesi" />
      <CampaignPageContain />
    </Fragment>
  );
};
export default CampaignList;
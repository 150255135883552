import React, { Fragment } from 'react';
import { Breadcrumbs } from '../../../AbstractElements';
import CategoryEditLayout from '../../../Component/Categories/CategoryEditLayout';


const CategoryEdit = () => {
  console.log("Ana sayfadayım")
  return (
    <Fragment>
      <Breadcrumbs parent="Menü" title="Kategori Oluştur" />
      <CategoryEditLayout />
    </Fragment>
  );
};
export default CategoryEdit;
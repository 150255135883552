import SweetAlert from 'sweetalert2';

export const errorHandler = (error, navigate) => {
  if (error.response) {
    const status = error.response.status;

    if (status === 403) {
      SweetAlert.fire({
        title: 'Erişim Engellendi!',
        text: 'Oturumunuz sona erdi. Lütfen yeniden giriş yapın.',
        icon: 'warning',
        confirmButtonText: 'Tamam',
      }).then(() => {
        navigate(`${process.env.PUBLIC_URL}/login`); // Login sayfasına yönlendir
      });
    } else if (status === 500) {
      SweetAlert.fire({
        title: 'Sunucu Hatası!',
        text: 'Bir şeyler ters gitti, lütfen daha sonra tekrar deneyin.',
        icon: 'error',
        confirmButtonText: 'Tamam',
      });
    } else {
      SweetAlert.fire({
        title: 'Hata!',
        text: `Beklenmeyen bir hata oluştu: ${error.response.data.message || 'Bilinmeyen hata'}`,
        icon: 'error',
        confirmButtonText: 'Tamam',
      });
    }
  } else if (error.request) {
    SweetAlert.fire({
      title: 'Bağlantı Hatası!',
      text: 'Sunucuya ulaşılamadı. Lütfen internet bağlantınızı kontrol edin.',
      icon: 'error',
      confirmButtonText: 'Tamam',
    });
  } else {
    SweetAlert.fire({
      title: 'Hata!',
      text: `Beklenmeyen bir hata oluştu: ${error.message}`,
      icon: 'error',
      confirmButtonText: 'Tamam',
    });
  }
};
import React, { Fragment } from "react";
import { FileText, LogIn, Mail, Settings, User } from "react-feather";
import { Link } from "react-router-dom";
import { Media } from "reactstrap";
import { H6, LI, UL } from "../../AbstractElements";
import { Account, Inbox, LogOut, Taskboard } from "../../Constant";
import Bookmark from "./Bookmark/index";
import ItemCart from "./ItemCart";
import Language from "./Langauge";
import MaxMiniSize from "./MaxMiniSize";
import MoonLight from "./MoonLight";
import Notification from "./Notification";

const HeaderContain = () => {
  // const navigate = useNavigate();
  const logout = () => {
    // window.location.reload(); 
    localStorage.removeItem("login");
    // navigate(`${process.env.PUBLIC_URL}/login`);
    window.location.href = `${process.env.PUBLIC_URL}/login`;
  };
  return (
    <Fragment>
      <div className='nav-right col-10 col-sm-6 pull-right right-header p-0 dash-76'>
        <UL attrUL={{ className: `simple-list flex-row nav-menus` }}>
          <MoonLight />
          <Notification />
          <MaxMiniSize />
          <LI attrLI={{ className: "profile-nav onhover-dropdown pe-0 pt-0 me-0" }}>
            <Media className='profile-media'>
              <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <g>
                  <g>
                    <path fillRule='evenodd' clipRule='evenodd' d='M9.55851 21.4562C5.88651 21.4562 2.74951 20.9012 2.74951 18.6772C2.74951 16.4532 5.86651 14.4492 9.55851 14.4492C13.2305 14.4492 16.3665 16.4342 16.3665 18.6572C16.3665 20.8802 13.2505 21.4562 9.55851 21.4562Z' stroke='#130F26' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round'></path>
                    <path fillRule='evenodd' clipRule='evenodd' d='M9.55849 11.2776C11.9685 11.2776 13.9225 9.32356 13.9225 6.91356C13.9225 4.50356 11.9685 2.54956 9.55849 2.54956C7.14849 2.54956 5.19449 4.50356 5.19449 6.91356C5.18549 9.31556 7.12649 11.2696 9.52749 11.2776H9.55849Z' stroke='#130F26' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round'></path>
                    <path d='M16.8013 10.0789C18.2043 9.70388 19.2383 8.42488 19.2383 6.90288C19.2393 5.31488 18.1123 3.98888 16.6143 3.68188' stroke='#130F26' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round'></path>
                    <path d='M17.4608 13.6536C19.4488 13.6536 21.1468 15.0016 21.1468 16.2046C21.1468 16.9136 20.5618 17.6416 19.6718 17.8506' stroke='#130F26' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round'></path>
                  </g>
                </g>
              </svg>
            </Media>
            <UL attrUL={{ className: `simple-list profile-dropdown onhover-show-div` }}>
              <LI>
                <Link to={`${process.env.PUBLIC_URL}/users/userprofile`}>
                  <i>
                    <User />
                  </i>
                  <span>{Account} </span>
                </Link>
              </LI>
              <LI>
                <Link to={`${process.env.PUBLIC_URL}/email/mailbox`}>
                  <i>
                    <Mail />
                  </i>
                  <span>{Inbox}</span>
                </Link>
              </LI>
              <LI>
                <Link to={`${process.env.PUBLIC_URL}/kanbanboard`}>
                  <i>
                    <FileText />
                  </i>
                  <span>{Taskboard}</span>
                </Link>
              </LI>
              <LI>
                <Link to={`${process.env.PUBLIC_URL}/users/useredit`}>
                  <i>
                    <Settings />
                  </i>
                  <span>Settings</span>
                </Link>
              </LI>
              <LI attrLI={{ className: "onhover-dropdown p-0", onClick: logout }}>
                <LogIn />
                <span>{LogOut}</span>
              </LI>
            </UL>
          </LI>
        </UL>
      </div>
    </Fragment>
  );
};
export default HeaderContain;

import React from 'react';
import { Progress } from 'reactstrap';

const Progressbar = ({ attrProgress = {}, children = '' }) => {
    
      return (
        <Progress  {...attrProgress} >{children}</Progress>
      );
};

export default Progressbar;
import { Btn } from '../../../AbstractElements';
import { Email, Password, Website, Submit, Cancel, Option, ContactNumber, CompanyName, Checkboxes, Radios,Image,  Disabled, title, shortDescription, conditions, usageInstructions, detailedDescription, startDate, endDate, CampaignDescription, CampaignConditions, CampaignHowToUse, CampaignDetail, CampaignStartDate, CampaignEndDate } from '../../../Constant';
import React, { Fragment, useState, useEffect} from 'react';
import { Row, Col, CardBody, CardFooter, Form, FormGroup, Label, Input, Button, Media } from 'reactstrap';
import ImageUpload from '../../../Pages/Bonus UI/ImageUpload';
import axios from "axios";
import SweetAlert from 'sweetalert2';
import { useLocation } from 'react-router';
import UploadImageContain from '../../Bonus UI/ImageUpload';


const CampaignEditPage = () => {

    const location = useLocation()
    const campaign = location.state?.campaign; // navigate ile gönderilen veriyi alıyoruz
    const [editFormValues, setEditFormValues] = useState({});
    const [isLoaded, setIsLoaded] = useState(false);


  const token = localStorage.getItem("token")

  const api = axios.create({
    baseURL: 'https://sea-lion-app-erqaa.ondigitalocean.app/admin/',
  });

  useEffect(() => {
    if (campaign) {
      setEditFormValues({
        title: campaign.title || '',
        shortDescription: campaign.shortDescription || '',
        conditions: campaign.conditions || '',
        usageInstructions: campaign.usageInstructions || '',
        detailedDescription: campaign.detailedDescription || '',
        startDate: campaign.startDate ? campaign.startDate.split('T')[0] : '',
        endDate: campaign.endDate ? campaign.endDate.split('T')[0] : '',
        priority: campaign.priority || 'Düşük',
        isActive: campaign.isActive || false,
        isPassive: campaign.isPassive || false,
        imageBase64: '',
        imageUrl: campaign.imageUrl || '',
      });
      setIsLoaded(true);
    }
  }, [campaign]);


   // onChange handler - input değiştiğinde güncellenir
   const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setEditFormValues((prevValues) => ({
      ...prevValues,
      [name]: type === 'checkbox' ? checked : value
    }));
  };


  const handleImageUpload = (base64Image) => {
    setEditFormValues((prevValues) => ({
      ...prevValues,
      imageBase64: base64Image,
      imageUrl: base64Image ? '' : prevValues.imageUrl
    }));
  };

  const handleFirstChange = (imageUrl) => {
      setEditFormValues((prevValues) => ({
      ...prevValues,
      imageUrl: campaign.imageUrl
    }));
  };

  const handleSubmit = async (e) => {

    console.log("Geldi?")

    // Tarih alanlarını istenen formata dönüştürüyoruz
    const formattedValues = {
      ...editFormValues,
      startDate: editFormValues.startDate ? new Date(editFormValues.startDate).toISOString() : '',
      endDate: editFormValues.endDate ? new Date(editFormValues.endDate).toISOString() : ''
    };

    console.log(token)
    

    try {
      const response = await api.put(`company/campaign/update/${campaign._id}`,  formattedValues  , {
        headers: {
          'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
        }
      }  );
      
      if (response.status === 200 && response.data.success === true) {
        console.log(response)
        SweetAlert.fire({
          title: 'Başarılı!',
          text: 'Kampanya Başarıyla Güncellendi',
          icon: 'success',
          confirmButtonText: 'Tamam',
      });
      }
    } catch (error) {
      console.log(error)
      SweetAlert.fire({
        title: 'Hata!',
        text: error,
        icon: 'error',
        confirmButtonText: 'Tamam',
    });
  }};

  return (
    <Fragment>
      <CardBody>
        {isLoaded && (
        <Form className="theme-form" onSubmit={handleSubmit}>
        <FormGroup className="row">
    <Label className="col-sm-3 col-form-label">Kampanya Görseli</Label>
    <Col sm="9">
        <ImageUpload
            initialImage={editFormValues.imageUrl}
            header="Kampanya Görselini Değiştir"
            onImageUpload={handleImageUpload}
        />
    </Col>
</FormGroup>
        
          <FormGroup className="row">
            <Label className="col-sm-3 col-form-label" htmlFor="inputText">{"Kampanya Başlığı"}</Label>
            <Col sm="9">
              <Input className="form-control" type="text" placeholder="Kampanya Başlığı" name="title" value={editFormValues.title}
                onChange={handleInputChange} />
            </Col>
          </FormGroup>
          <FormGroup className="row">
            <Label className="col-sm-3 col-form-label" htmlFor="inputText">{CampaignDescription}</Label>
            <Col sm="9">
              <Input className="form-control" type="text" name="shortDescription" placeholder="Banner'da gözükecek kısa açıklama yazın" value={editFormValues.shortDescription}
                onChange={handleInputChange} />
            </Col>
          </FormGroup>
          <FormGroup className="row">
            <Label className="col-sm-3 col-form-label" htmlFor="inputText">{CampaignConditions}</Label>
            <Col sm="9">
              <Input className="form-control" type="text" name="conditions" placeholder="Kampanya için özel koşul varsa doldurun" value={editFormValues.conditions}
                onChange={handleInputChange}/>
            </Col>
          </FormGroup>
          <FormGroup className="row">
            <Label className="col-sm-3 col-form-label" htmlFor="inputText">{CampaignHowToUse}</Label>
            <Col sm="9">
              <Input className="form-control" type="text" name="usageInstructions" placeholder="Nasıl Kullanılır?" value={editFormValues.usageInstructions}
                onChange={handleInputChange} />
            </Col>
          </FormGroup>
          <FormGroup className="row">
            <Label className="col-sm-3 col-form-label" htmlFor="inputText">{CampaignDetail}</Label>
            <Col sm="9">
              <Input className="form-control" type="text" name="detailedDescription" placeholder="Detaylı Açıklama" value={editFormValues.detailedDescription}
                onChange={handleInputChange}/>
            </Col>
          </FormGroup>
          <FormGroup className="row">
            <Label className="col-sm-3 col-form-label" htmlFor="inputText">{CampaignStartDate}</Label>
            <Col sm="9">
              <Input className="form-control" type="date" name= "startDate" placeholder="Kampanya Başlangıç Tarihi" value={editFormValues.startDate}
                onChange={handleInputChange} />
            </Col>
          </FormGroup>
          <FormGroup className="row">
            <Label className="col-sm-3 col-form-label" htmlFor="inputText">{CampaignEndDate}</Label>
            <Col sm="9">
              <Input className="form-control" type="date" name= "endDate" placeholder="Kampanya Bitiş Tarihi" value={editFormValues.endDate}
                onChange={handleInputChange} />
            </Col>
          </FormGroup>
          <FormGroup className="mb-3">
            <Row>
              <Label className="col-form-label col-sm-3 pt-0">Öncelik</Label>
              <Col sm="9">
              <div className="form-check">
                  <Input type="radio" name="priority" value="Çok Yüksek" onChange={handleInputChange} checked={editFormValues.priority === 'Çok Yüksek'} /> Çok Yüksek Öncelikli
                </div>
                <div className="form-check">
                  <Input type="radio" name="priority" value="Yüksek" onChange={handleInputChange} checked={editFormValues.priority === 'Yüksek'} /> Yüksek Öncelikli
                </div>
                <div className="form-check">
                  <Input type="radio" name="priority" value="Orta" onChange={handleInputChange} checked={editFormValues.priority === 'Orta'} /> Orta Öncelikli
                </div>
                <div className="form-check">
                  <Input type="radio" name="priority" value="Düşük" onChange={handleInputChange} checked={editFormValues.priority === 'Düşük'} /> Düşük Öncelikli
                </div>
                <div className="form-check">
                  <Input type="radio" name="priority" value="Çok Düşük" onChange={handleInputChange} checked={editFormValues.priority === 'Çok Düşük'} /> Çok Düşük Öncelikli
                </div>
              </Col>
            </Row>
          </FormGroup>
          <div className="row mb-0 horizontal-layout">
            <Label className="col-sm-3 col-form-Label pb-0 mb-0">Kampanya Durumu</Label>
            <Col sm="9">
              <div className="mb-0">
                <div className="form-check form-check-inline checkbox checkbox-primary">
                  <Input id="inline-form-1" type="checkbox" name="isActive" checked={editFormValues.isActive} onChange={handleInputChange} />
                  <Label className="mb-0" htmlFor="inline-form-1">Aktif</Label>
                </div>
                <div className="form-check form-check-inline checkbox checkbox-primary">
                  <Input id="inline-form-2" type="checkbox" name="isPassive" checked={editFormValues.isPassive} onChange={handleInputChange} />
                  <Label className="mb-0" htmlFor="inline-form-2">Pasif</Label>
                </div>
              </div>
            </Col>
          </div>
        </Form>
        )}        
      </CardBody>
      <CardFooter>
        <Btn type='submit'attrBtn={{ color: 'primary', className: 'me-2',  onClick: () => handleSubmit()}} >Kaydet</Btn>
        <Btn attrBtn={{ color: 'primary' }}>İptal</Btn>
      </CardFooter>
    </Fragment>
  );
};
export default CampaignEditPage;
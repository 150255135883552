import React, { Fragment } from 'react';
import { Breadcrumbs } from '../../../AbstractElements';
import FormDefaultContain from '../../../Component/Forms/Form Layout/Form Default/HorizontalLayout';


const FormDefault = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="Kampanyalar" title="Kampanya Oluştur" />
      <FormDefaultContain />
    </Fragment>
  );
};
export default FormDefault;
import { useState } from "react";
import { Button, Form, FormGroup, Input, InputGroup, InputGroupText, Label, Media } from "reactstrap";
import { H4, H6, Image, LI, P, UL } from '../AbstractElements';
import { Link, useNavigate } from "react-router-dom";
import { CreateAccount, EmailAddress, ForgotPassword, HorizontalAlignment, Login, Password, RememberPassword, SignIn } from "../Constant";
import { toast } from "react-toastify";
import { Mail, Lock } from "react-feather";
import img from '../assets/images/blog/cofloyalx.png';
import MediaIcons from "../CommonElements/MediaIcons";
import axios from "axios";
import SweetAlert from 'sweetalert2';


export default function SignInForm() {
  const history = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isPasswordVisible,setisPasswordVisible] = useState(false);

  const toggle = () =>{ setisPasswordVisible(!isPasswordVisible)};

  const api = axios.create({
    baseURL: 'https://sea-lion-app-erqaa.ondigitalocean.app/admin/'
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post('/login', { email, password });
      if (response.status === 200 && response.data.success === true) {
        console.log(response)
        const token = response.data.data.token;
        toast.success("Giriş Başarılı...!");
        localStorage.setItem("token", token)
        history(`${process.env.PUBLIC_URL}/dashboard/default`);
        localStorage.setItem("login", JSON.stringify(true));
      }
    } catch (error) {
      console.log(error)
      SweetAlert.fire({
        title: 'Hata!',
        text: 'Lütfen geçerli bir mail adresi ya da şifre girin...!',
        icon: 'error',
        confirmButtonText: 'Tamam',
    });
    }

  };

  return (
    <div className='login-card1'>

      <Form className='theme-form login-form'>
        <div className='login-header text-center'>
        <Image attrImage={{ body: true, className: 'img-100 rounded-circle', src: `${img}`, alt: '#' }}>{HorizontalAlignment}</Image>
          <h4>{Login}</h4>
          <h6>Hoşgeldiniz! Cofloyal dünyasına giriş yapmak için gerekli bilgileri giriniz.</h6>
        </div>

        <FormGroup>
          <Label>{EmailAddress}</Label>
          <InputGroup>
            <InputGroupText>
              <Mail />
            </InputGroupText>
            <Input type='email' required placeholder='Email adresinizi giriniz' defaultValue={email} onChange={(event) => setEmail(event.target.value)} />
          </InputGroup>
        </FormGroup>
        <FormGroup>
          <Label>{Password}</Label>
          <InputGroup>
            <InputGroupText>
              <Lock />
            </InputGroupText>
            <Input type={isPasswordVisible ? "text" : "password"} name='login[password]' required defaultValue={password} onChange={(event) => setPassword(event.target.value)} placeholder="Şifrenizi girin" />
            
          
            <div className='show-hide' onClick={toggle}>
              <span className={!isPasswordVisible ? "show" : ""}></span>
            </div>
          </InputGroup>
        </FormGroup>

        <FormGroup className='login-btn'>
          <div className='checkbox'>
            <Input id='checkbox1' type='checkbox' />
            <Label className='text-muted' for='checkbox1'>
              {RememberPassword}
            </Label>
          </div>
          <Link href='/pages/authentication/forgetpwd' className='link'>
            {ForgotPassword}?
          </Link>
        </FormGroup>
        <FormGroup>
          <Button className='btn-block' color='primary' type='submit' onClick={handleSubmit}>
            {SignIn}
          </Button>
        </FormGroup>
      </Form>
    </div>
  );
}

import React, { Fragment } from 'react';
import UploadImageContain from '../../Component/Bonus UI/ImageUpload';
import { Breadcrumbs } from '../../AbstractElements';

const ImageUpload  = ({ header = 'Varsayılan Başlık', initialImage, onImageUpload })=> {
  return (
    
      <UploadImageContain header= {header} onImageUpload={onImageUpload} initialImage={initialImage} />
    
  );
};

export default ImageUpload;